<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="法人姓名" prop="legalRealName">
                  <a-input v-model="queryParam.legalRealName" placeholder="请输入法人姓名" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="法人联系方式" prop="legalMobile">
                  <a-input v-model="queryParam.legalMobile" placeholder="请输入法人联系方式" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="法人身份证" prop="legalCard">
                  <a-input v-model="queryParam.legalCard" placeholder="请输入法人身份证" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="身份证-人像面" prop="portraitPlane">
                  <a-input v-model="queryParam.portraitPlane" placeholder="请输入身份证-人像面" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="身份证-国徽面" prop="emblemPlane">
                  <a-input v-model="queryParam.emblemPlane" placeholder="请输入身份证-国徽面" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="营业执照" prop="businessLicense">
                  <a-input v-model="queryParam.businessLicense" placeholder="请输入营业执照" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="公司名稱" prop="companyName">
                  <a-input v-model="queryParam.companyName" placeholder="请输入公司名稱" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="统一社会信用码" prop="socialCredit">
                  <a-input v-model="queryParam.socialCredit" placeholder="请输入统一社会信用码" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="法定经营范围" prop="businessScope">
                  <a-input v-model="queryParam.businessScope" placeholder="请输入法定经营范围" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="用戶编号" prop="userId">
                  <a-input v-model="queryParam.userId" placeholder="请输入用戶编号" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="拒绝原因" prop="refusalCause">
                  <a-input v-model="queryParam.refusalCause" placeholder="请输入拒绝原因" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="店铺名稱" prop="storeName">
                  <a-input v-model="queryParam.storeName" placeholder="请输入店铺名稱" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="店铺logo" prop="storeLogo">
                  <a-input v-model="queryParam.storeLogo" placeholder="请输入店铺logo" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="店铺背景" prop="storeBackground">
                  <a-input v-model="queryParam.storeBackground" placeholder="请输入店铺背景" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="店铺行业" prop="typeId">
                  <a-input v-model="queryParam.typeId" placeholder="请输入店铺行业" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="套餐编号" prop="classId">
                  <a-input v-model="queryParam.classId" placeholder="请输入套餐编号" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="保证金" prop="integralBail">
                  <a-input v-model="queryParam.integralBail" placeholder="请输入保证金" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="服务费" prop="integralServe">
                  <a-input v-model="queryParam.integralServe" placeholder="请输入服务费" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="店铺-国家" prop="storeCountry">
                  <a-input v-model="queryParam.storeCountry" placeholder="请输入店铺-国家" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="店铺-地址省" prop="storeProvinceName">
                  <a-input v-model="queryParam.storeProvinceName" placeholder="请输入店铺-地址省" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="店铺-地址市" prop="storeCityName">
                  <a-input v-model="queryParam.storeCityName" placeholder="请输入店铺-地址市" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="店铺-地址区" prop="storeCountyName">
                  <a-input v-model="queryParam.storeCountyName" placeholder="请输入店铺-地址区" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="地址经度" prop="storeLongitude">
                  <a-input v-model="queryParam.storeLongitude" placeholder="请输入地址经度" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="地址维度" prop="storeDimensionality">
                  <a-input v-model="queryParam.storeDimensionality" placeholder="请输入地址维度" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="公司-国家" prop="companyCountry">
                  <a-input v-model="queryParam.companyCountry" placeholder="请输入公司-国家" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="公司-地址省" prop="companyProvinceName">
                  <a-input v-model="queryParam.companyProvinceName" placeholder="请输入公司-地址省" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="公司-地址市" prop="companyCityName">
                  <a-input v-model="queryParam.companyCityName" placeholder="请输入公司-地址市" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="公司-地址区" prop="companyCountyName">
                  <a-input v-model="queryParam.companyCountyName" placeholder="请输入公司-地址区" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="公司-地址经度" prop="companyLongitude">
                  <a-input v-model="queryParam.companyLongitude" placeholder="请输入公司-地址经度" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="公司-地址维度" prop="companyDimensionality">
                  <a-input v-model="queryParam.companyDimensionality" placeholder="请输入公司-地址维度" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="公司-详细地址" prop="companyAddress">
                  <a-input v-model="queryParam.companyAddress" placeholder="请输入公司-详细地址" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="店铺-简介" prop="storeDetail">
                  <a-input v-model="queryParam.storeDetail" placeholder="请输入店铺-简介" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="营业开始時間" prop="openStartTime">
                  <a-input v-model="queryParam.openStartTime" placeholder="请输入营业开始時間" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="营业结束時間" prop="openEndTime">
                  <a-input v-model="queryParam.openEndTime" placeholder="请输入营业结束時間" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="店铺-客服电话" prop="storeCall">
                  <a-input v-model="queryParam.storeCall" placeholder="请输入店铺-客服电话" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="店铺-负责人" prop="storePrincipal">
                  <a-input v-model="queryParam.storePrincipal" placeholder="请输入店铺-负责人" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="店铺-负责人电话" prop="principalCall">
                  <a-input v-model="queryParam.principalCall" placeholder="请输入店铺-负责人电话" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="星级" prop="starLevel">
                  <a-input v-model="queryParam.starLevel" placeholder="请输入星级" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="收藏人数" prop="collectionNum">
                  <a-input v-model="queryParam.collectionNum" placeholder="请输入收藏人数" allow-clear/>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查詢</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()">
          <a-icon type="plus" />新增
        </a-button>
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)">
          <a-icon type="edit" />修改
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete">
          <a-icon type="delete" />刪除
        </a-button>
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical"/>
          <a @click="handleDelete(record)">
            <a-icon type="delete" />刪除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageStoreCertification,listStoreCertification, delStoreCertification } from '@/api/store/storeCertification'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'StoreCertification',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查詢参数
      queryParam: {
        merchantType: null,
        certificationType: null,
        legalRealName: null,
        legalMobile: null,
        legalCard: null,
        portraitPlane: null,
        emblemPlane: null,
        businessLicense: null,
        companyName: null,
        socialCredit: null,
        businessScope: null,
        userId: null,
        applicationStatus: null,
        refusalCause: null,
        storeName: null,
        storeLogo: null,
        storeBackground: null,
        typeId: null,
        classId: null,
        integralBail: null,
        integralServe: null,
        storeCountry: null,
        storeProvinceName: null,
        storeCityName: null,
        storeCountyName: null,
        storeDetailedAddress: null,
        storeLongitude: null,
        storeDimensionality: null,
        companyCountry: null,
        companyProvinceName: null,
        companyCityName: null,
        companyCountyName: null,
        companyDetailedAddress: null,
        companyLongitude: null,
        companyDimensionality: null,
        companyAddress: null,
        storeDetail: null,
        openStartTime: null,
        openEndTime: null,
        storeCall: null,
        storePrincipal: null,
        principalCall: null,
        doorPicture: null,
        starLevel: null,
        collectionNum: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '${comment}',
          dataIndex: 'id',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '商家類型',
          dataIndex: 'merchantType',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '认证類型',
          dataIndex: 'certificationType',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '法人姓名',
          dataIndex: 'legalRealName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '法人联系方式',
          dataIndex: 'legalMobile',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '法人身份证',
          dataIndex: 'legalCard',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '身份证-人像面',
          dataIndex: 'portraitPlane',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '身份证-国徽面',
          dataIndex: 'emblemPlane',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '营业执照',
          dataIndex: 'businessLicense',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '公司名稱',
          dataIndex: 'companyName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '统一社会信用码',
          dataIndex: 'socialCredit',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '法定经营范围',
          dataIndex: 'businessScope',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '用戶编号',
          dataIndex: 'userId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '申请狀態',
          dataIndex: 'applicationStatus',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '拒绝原因',
          dataIndex: 'refusalCause',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '店铺名稱',
          dataIndex: 'storeName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '店铺logo',
          dataIndex: 'storeLogo',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '店铺背景',
          dataIndex: 'storeBackground',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '店铺行业',
          dataIndex: 'typeId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '套餐编号',
          dataIndex: 'classId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '保证金',
          dataIndex: 'integralBail',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '服务费',
          dataIndex: 'integralServe',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '店铺-国家',
          dataIndex: 'storeCountry',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '店铺-地址省',
          dataIndex: 'storeProvinceName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '店铺-地址市',
          dataIndex: 'storeCityName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '店铺-地址区',
          dataIndex: 'storeCountyName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '店铺-详细地址',
          dataIndex: 'storeDetailedAddress',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '地址经度',
          dataIndex: 'storeLongitude',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '地址维度',
          dataIndex: 'storeDimensionality',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '公司-国家',
          dataIndex: 'companyCountry',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '公司-地址省',
          dataIndex: 'companyProvinceName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '公司-地址市',
          dataIndex: 'companyCityName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '公司-地址区',
          dataIndex: 'companyCountyName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '公司-详细地址',
          dataIndex: 'companyDetailedAddress',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '公司-地址经度',
          dataIndex: 'companyLongitude',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '公司-地址维度',
          dataIndex: 'companyDimensionality',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '公司-详细地址',
          dataIndex: 'companyAddress',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '店铺-简介',
          dataIndex: 'storeDetail',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '营业开始時間',
          dataIndex: 'openStartTime',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '营业结束時間',
          dataIndex: 'openEndTime',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '店铺-客服电话',
          dataIndex: 'storeCall',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '店铺-负责人',
          dataIndex: 'storePrincipal',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '店铺-负责人电话',
          dataIndex: 'principalCall',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '店铺-门头照(多个逗号隔开)',
          dataIndex: 'doorPicture',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '星级',
          dataIndex: 'starLevel',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '插入時間',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '備註',
          dataIndex: 'remark',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '收藏人数',
          dataIndex: 'collectionNum',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查詢店铺-商家认证列表 */
    getList () {
      this.loading = true
     pageStoreCertification(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        merchantType: undefined,
        certificationType: undefined,
        legalRealName: undefined,
        legalMobile: undefined,
        legalCard: undefined,
        portraitPlane: undefined,
        emblemPlane: undefined,
        businessLicense: undefined,
        companyName: undefined,
        socialCredit: undefined,
        businessScope: undefined,
        userId: undefined,
        applicationStatus: undefined,
        refusalCause: undefined,
        storeName: undefined,
        storeLogo: undefined,
        storeBackground: undefined,
        typeId: undefined,
        classId: undefined,
        integralBail: undefined,
        integralServe: undefined,
        storeCountry: undefined,
        storeProvinceName: undefined,
        storeCityName: undefined,
        storeCountyName: undefined,
        storeDetailedAddress: undefined,
        storeLongitude: undefined,
        storeDimensionality: undefined,
        companyCountry: undefined,
        companyProvinceName: undefined,
        companyCityName: undefined,
        companyCountyName: undefined,
        companyDetailedAddress: undefined,
        companyLongitude: undefined,
        companyDimensionality: undefined,
        companyAddress: undefined,
        storeDetail: undefined,
        openStartTime: undefined,
        openEndTime: undefined,
        storeCall: undefined,
        storePrincipal: undefined,
        principalCall: undefined,
        doorPicture: undefined,
        starLevel: undefined,
        collectionNum: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 刪除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认刪除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delStoreCertification(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '刪除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空刪除ids列表
          that.ids = []
        }
      })
    },
    /** 導出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认導出?',
        content: '此操作将導出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('platform/store-certification/export', {
            ...that.queryParam
          }, `店铺-商家认证_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
